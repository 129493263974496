import React from "react"
import { graphql } from "gatsby"
import Layout from '../components/layout';
import SEO from '../components/seo';


export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  
   const pageStyles = {
        color: "#232129",
        padding: `0.3em 1em 1em 1em`,
        fontFamily: "-apple-system, Roboto, sans-serif, serif",
    }

    const tile = "Atex Travel";
    const description = "Travel information for traveling in the Stowmarket area, including getting to and from work.";
    const publishDate = "2021-12-21";
    const tags = "Suffolk travel business";
    const img =  data.site.siteMetadata.siteUrl + data.site.siteMetadata.image;
    const imgHeight = data.site.siteMetadata.imgHeight;
    const imgWidth = data.site.siteMetadata.imgWidth;
    const url = data.site.siteMetadata.siteUrl;
    
    const schema = {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "image": {
            "@type": "ImageObject",
            "url": img,
            "width": imgWidth,
            "height": imgHeight
        },
        "name": "Atex Travel",
        "url": url,
        "description": description,
        "datePublished": publishDate,
        "publisher": {
            "@type": "Organization",
            "@id": url+"#organization",
            "url": url,
            "name": "Atex",
            "description": "Stowmarket Business park",
            "logo": {
                "@type": "ImageObject",
                "@id": url+"#logo",
                "url": img,
                "width": imgWidth,
                "height": imgHeight
            },
        },
        "keywords": tags
    };

  return (
    <Layout>
        <SEO title="Atex Travel" description={description} date={publishDate} image={img} tags={tags}  schemaMarkup={schema} />
      <main
      dangerouslySetInnerHTML={{ __html: html }}
      />
    <em>{frontmatter.date}</em>
    </Layout>
  );

}

export const pageQuery = graphql`
  query($id: String!) {
    site {
        siteMetadata {
            siteUrl
            image
            imageHeight
            imageWidth
            title
        }
      }
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`
